define("discourse/plugins/discourse-category-tokenization/discourse/services/token-info-service", ["exports", "@ember/service", "@glimmer/tracking"], function (_exports, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TokenInfoService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "tokenCategoryData", [_tracking.tracked], function () {
      return false;
    }))();
    #tokenCategoryData = (() => (dt7948.i(this, "tokenCategoryData"), void 0))();
    setTo(data) {
      this.tokenCategoryData = data;
    }
  }
  _exports.default = TokenInfoService;
});