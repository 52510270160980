define("discourse/plugins/discourse-category-tokenization/discourse/connectors/before-create-topic-button/tokens-info-banner", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // connectors/before-create-topic-button/tokens-info-banner.js

  class TokensInfoConnectorComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "pluginPosition", [_service.inject]))();
    #pluginPosition = (() => (dt7948.i(this, "pluginPosition"), void 0))();
    get shouldRender() {
      return this.siteSettings.plugin_position === "before-create-topic-button";
    }
  }
  _exports.default = TokensInfoConnectorComponent;
});