define("discourse/plugins/discourse-category-tokenization/discourse/services/token-data", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/models/category"], function (_exports, _service, _ajax, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Import ajax from discourse/lib/ajax
  // Import Category model

  class TokenDataService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    tokenData = null;
    async loadTokenData() {
      const userId = this.currentUser && this.currentUser.id;
      const categorySlugPathWithID = this.router.currentRoute.params.category_slug_path_with_id;
      if (!userId || !categorySlugPathWithID) {
        this.tokenData = null;
        return;
      }
      const category = await _category.default.findBySlugPathWithID(categorySlugPathWithID);
      const categoryId = category && category.id;
      if (!categoryId) {
        this.tokenData = null;
        return;
      }
      const response = await (0, _ajax.ajax)("/discourse-category-tokenization/get-token-details", {
        type: "GET",
        data: {
          user_id: userId,
          category_id: categoryId
        }
      });
      this.tokenData = response;
    }
  }
  _exports.default = TokenDataService;
});